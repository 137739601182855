<template>
    <div class="faq">
        <h3 class="is-size-4">Häufig gestellte Fragen</h3>
        <b-collapse class="card" animation="slide" v-for="(collapse, index) of collapses" :key="index"
            :open="isOpen == index" @open="isOpen = index">
            <template #trigger="props">
                <div class="card-header" role="button">
                    <p class="card-header-title">
                        {{ collapse.title }}
                    </p>
                    <a class="card-header-icon">
                        <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <p v-html="collapse.text"></p>
                    <Address v-if="collapse.special=='address'" />
                    
                </div>

            </div>
        </b-collapse>
    </div>
</template>
<style>
    .faq {
        margin-bottom: 20px;
    }
</style>
<script>
    export default {
        data: function () {
            return {
                isOpen: 0,
                collapses: [{
                        title: 'Wie viele Dominosteine benötige ich?',
                        text: 'Dies kommt auf die Dauer des Projektaufbaus und der Anzahl Mitwirkenden an. Grundsätzlich kann man mit <b>1\'000 Dominosteinen pro Teilnehmer</b> rechnen. Wird der Projektaufbau über mehrere Tage durchgeführt und das Konstrukt nicht fortlaufend (absichtlich) zerstört, so erhöht sich die benötigte Anzahl Dominosteine entsprechend. Gerne können Sie uns bei Ihrer Anfrage Ihr Vorhaben schildern und uns nach der passenden Menge an Dominosteinen fragen. Wir unterstützen Sie gerne.',
                        special: 'calccomp'
                    },
                    {
                        title: 'Kann ich neben der Vermietung auch einen Workshop buchen?',
                        text: 'Gerne geben wir unsere Erfahrungen rund um Kettenreaktionen mit Dominosteinen persönlich in Form eines Workshops weiter. Dieser kann von einer kurzen Einführung in die Welt der Domino-Kettenreaktionen bis zu einer mehrtagig geführten Projektwoche andauern. Wir sind offen für Ihre Vorschläge und wägen gerne die Möglichkeiten mit Ihnen ab.'
                    },
                    {
                        title: 'Wo kann ich die Dominosteine abholen?',
                        text: 'Dominosteine einer Miete können an unserer Lageradresse abgeholt werden.',
                        special: 'address'
                    },
                    {
                        title: 'Wie und wann muss die Vermietung bezahlt werden?',
                        text: 'Nach einer erfolgreichen Vermietung senden wir Ihnen die entsprechende Rechnung per E-Mail zu. Diese gilt es innert 30 Tagen zu begleichen.'
                    }
                ]
            }
        }
    }
</script>