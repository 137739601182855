<template>
    <section>
        <div class="notification is-orange" v-if="ad_shown">
            <h1 class="has-white-text"><span>SDE</span> <span>@School</span></h1>

            <div class="columns is-desktop is-vcentered">
                <div class="column is-6">
                    <img :src="'./img/headers/sde_at_school_domino_portal.png'" />
                </div>
                <div class="column is-6">
                    <div class="content is-large has-text-centered">
                        <p>Erhalten Sie Zugang zu unserem <strong>SDE @School Portal</strong>. Hier finden Sie alle
                            benötigten Unterlagen,
                            um Ihr eigenes Dominoevent zu gestalten.</p>
                        <div class="buttons is-centered">
                            <router-link :to="{name: 'mieten'}" class="button is-medium">Zugang erhalten</router-link>
                            <a :to="{}" class="button is-primary is-medium" @click="isModalShown = true">Mehr
                                Informationen</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr>
        <div class="modal" v-bind:class="{'is-active' : isModalShown || modal_shown}">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <section class="hero is-medium is-dark has-background is-hidden-mobile">
                    <img :src="`./img/headers/sde_at_school_domino_portal.png`"
                        class="is-transparent hero-background" />
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title is-1">
                                Online Portal für Schulen
                            </h1>
                        </div>
                    </div>
                </section>
                <section class="modal-card-body">
                    <div class="content is-large">
                        <div class="is-hidden-tablet">
                            <h1 class=" title is-3">
                                Online Portal für Schulen
                            </h1>
                            <img :src="`./img/headers/sde_at_school_domino_portal.png`" />
                        </div>
                        <p>Aufgrund der hohen Nachfrage nach Workshops und Einführungen ins Dominobauen, haben wir uns
                            entschieden sämtliche Hilfsmittel in einem Online-Portal zu sammeln.</p>
                        <p>Dieses beinhaltet folgende Module:</p>
                        <ul>
                            <li>Ausführliche Anleitungen zu sämtlichen Techniken unterstützt durch Bilder & Videos</li>
                            <li>Unterlagen zur Durchführung von Dominoprojekten</li>
                            <li>Unterlagen für Einführungslektionen an Schulen.</li>
                            <li>Diverse Vorlagen zum Aufbau von Konstrukten</li>
                        </ul>
                        <div class="notification is-light">Der Zugang ist kostenpflichtig und dauert von der
                            gegenseitigen Bestätigung bis zum Ende einer Vermietung. Somit haben Sie als Organisator*in
                            ausreichend Zeit, mit den benötigten Materialien Ihr Projekt vorzubereiten.</div>
                        <div class="buttons is-centered">
                            <button @click="get_access_action" class="button is-primary is-medium">Zugang
                                erhalten</button>
                            <router-link :to="{name: 'portal'}" class="button is-primary is-outlined is-medium"
                                target="_blank">Zum Portal</router-link>
                            <button class="button is-medium" @click="close">Schliessen</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    </section>
</template>
<style scoped>
    .is-orange {
        background-color: orange;
        color: white;
    }

    .orange {
        color: orange;
    }

    .blue {
        color: #197CD5;
    }

    .has-white-text {
        color: white;
    }

    img {
        width: 100%;
    }
</style>
<script>
    export default {
        data: function () {
            return {
                isModalShown: false
            }
        },
        props: {
            modal_shown: {
                type: Boolean,
                required: false,
                default: false
            },
            ad_shown: {
                type: Boolean,
                required: false,
                default: true
            },
            close_action: {
                type: Function,
                required: false
            },
            get_access_action: {
                type: Function,
                required: false,
                default: function () {
                    this.$router.push({
                        name: 'mieten'
                    })
                }
            }
        },
        methods: {
            close() {
                if (this.close_action) {
                    this.close_action()
                    return
                }
                this.isModalShown = false
            },
            portal_access_click() {
                this.get_access_action()
            }
        }
    }
</script>