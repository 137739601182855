<template>
    <div>
        <section class="hero is-medium is-dark has-background">
            <img :src="header_image" class="is-transparent hero-background" />
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-1 is-size-2-mobile">
                        Mieten von Dominosteinen
                    </h1>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="content is-medium">
                    <p class="subtitle is-4">
                        Möchten Sie ein Projekt mit Dominos an Ihrer Schule, bei Ihrer Firma oder bei Ihnen zu Hause
                        realisieren? Gerne vermieten wir Dominosteine in diversen Farben und Mengen Ihrer Wahl direkt
                        über unseren Kostenrechner.
                    </p>
                    <RentCalculator />
                    <RentFAQ />
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import RentFAQ from '@/components/rent/RentFAQ'
    import RentCalculator from '@/components/rent/RentCalculator'
    export default {
        metaInfo: {
            title: 'Mieten von Dominosteinen',
            meta: [{
                    name: 'description',
                    content: 'Möchten Sie Dominosteine für Ihre Schule, ihren Betrieb oder gar privat für Zuhause mieten? Dann sind Sie bei uns genau richtig. Wir vermieten tausende von Dominosteinen spontan und flexibel. Erstellen Sie online über unsere Website eine Anfrage.'
                },
                {
                    name: 'keywords',
                    content: 'Dominosteine mieten, Farbige, Für Schüler und Kinder, Aktivitäten, Klassenaktivitäten, Schulprojekte, Schweiz, Kostenrechner'
                }
            ],
        },
        data: function () {
            return {
                header_image: 'https://swissdominoeffect.com/img/headers/mieten_domino_banner.png'
            }
        },
        components: {
            RentCalculator,
            RentFAQ
        }
    }
</script>